import React, { useEffect, useMemo, useState } from 'react';
import { PageWrap, PageHeader } from 'ui/styled';
import { CoursesList } from './components';
import Select from 'react-select';
import coursesStore from 'store/coursesStore';
import { observer } from 'mobx-react';
import { Pagination } from 'ui/components/Pagination';
import { useSearchParams } from 'react-router-dom';

const FILTER_OPTIONS = [
    { value: 'all', label: 'Все курсы' },
    { value: 1, label: 'На проверке' },
    { value: 0, label: 'Одобренные' },
    { value: 2, label: 'Отклонённые' },
    { value: 3, label: 'Новые' },
];

const ModerPage = observer(() => {
    const [filter, setFilter] = useState(FILTER_OPTIONS[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [initializing, setInitializing] = useState(true)

    useEffect(() => {
        const offsetParam = searchParams.get('offset');
        const initialFilter = FILTER_OPTIONS.find((option) => option.value === (offsetParam ? +offsetParam : offsetParam)) || FILTER_OPTIONS[0];

        setFilter(initialFilter);
        setInitializing(false);
    }, [searchParams])

    useEffect(() => {
        if (initializing) return;

        const fetchCourses = async () => {
            setLoading(true);
            try {
                const { value } = filter;

                if (value === 'all') {
                    await coursesStore.loadCourses(currentPage);
                } else {
                    await coursesStore.loadCourses(currentPage, 10, true, value);
                }

                setLoading(false);
                setTotalPages(coursesStore.totalPages);
                setSearchParams((prevParams) => {
                    const params = new URLSearchParams(prevParams);
                    params.set('offset', value);
                    console.log(params.get('offset'))
                    return params;
                });
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        };

        fetchCourses();
    }, [currentPage, filter, initializing]);

    if (initializing) {
        return <p>Загрузка...</p>
    }

    if (error) {
        return <p>Произошла ошибка при загрузке курсов</p>;
    }


    return (
        <PageWrap>
            <PageHeader>
                <Select
                    options={FILTER_OPTIONS}
                    defaultValue={filter}
                    onChange={(selectedOption) => {
                        setFilter(selectedOption);
                        setCurrentPage(1);
                    }}
                />
            </PageHeader>
            <CoursesList filteredCourses={coursesStore.courses} loading={loading} />
            {
                coursesStore.courses.length !== 0 && (
                    <Pagination totalPages={totalPages} currentPage={currentPage} setIndex={setCurrentPage} />
                )
            }
        </PageWrap>
    );
});

export default ModerPage;
