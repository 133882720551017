import { LinkOverlay } from "../AdvancedSettingsPopup/styled";
import { ButtonContainer, CopyButton, CrossWrap, DeleteButton, PopupContainer } from "./styled";
import { MdDelete } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { IoCopy } from "react-icons/io5";
import { useState } from "react";
import { CopiedButton } from "routes/pages/GroupsPage/components";
import { ConfirmationPopup, DeleteModal } from "./components";
import groupsStore from "store/groupsStore";
import { useModal } from "routes/pages/GroupsPage/hooks";

const LinksPopup = ({ handleCloseSettingsPopup, handleCloseIsLinkModalVisible, id, handleCloseLinkModal }) => {
    const [visibleCopied, setVisibleCopied] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const { isVisible, openModal, closeModal } = useModal();

    const getBaseUrl = () => {
        const url = new URL(window.location.href);
        return `${url.protocol}//${url.hostname}`;
    }

    const handleCopy = async (e) => {
        e.preventDefault();
        await groupsStore.copyLink(id);

        if (groupsStore.link) {
            const url = process.env.REACT_APP_LINK ? process.env.REACT_APP_LINK : getBaseUrl()

            navigator.clipboard.writeText(`${url}invite/group/` + groupsStore.link)
                .then(() => {
                    groupsStore.copyLink(id);
                    setVisibleCopied(!visibleCopied);
                })
                .catch((err) => {
                    console.error("Ошибка копирования ссылки", err);
                });
        }
    };

    const confirmDelete = () => {
        groupsStore.deleteLink(id);
        closeModal();
        setIsDeleted(true);
    };

    const closeDeleteConfirmation = () => {
        setIsDeleted(false);
        closeModal();
        handleCloseIsLinkModalVisible();
        handleCloseSettingsPopup();
    }
    return (
        <>
            <LinkOverlay onClick={handleCloseLinkModal} />
            <PopupContainer>
                <ButtonContainer>
                    {visibleCopied ? (
                        <CopiedButton />
                    ) : (
                        <CopyButton onClick={handleCopy}>
                            <span>Скопировать</span>
                            <IoCopy />
                        </CopyButton>
                    )}

                    <DeleteButton onClick={openModal}>
                        <MdDelete style={{ fontSize: 20 }} />
                    </DeleteButton>
                    <CrossWrap onClick={handleCloseLinkModal}>
                        <RxCross1 />
                    </CrossWrap>
                </ButtonContainer>
            </PopupContainer>

            {isVisible && (
                <DeleteModal onClose={closeModal} onConfirm={confirmDelete} />
            )}

            {isDeleted && (
                <ConfirmationPopup onClose={closeDeleteConfirmation} />
            )}
        </>
    );
};


export default LinksPopup;


