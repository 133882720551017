import React, { useEffect, useState, useCallback } from 'react';
import { getUsers } from './api';
import { UserCard } from './components';
import { PageHeader, PageWrap } from 'ui/styled';
import Select from 'react-select';
import UsersList from './components/UsersList';
import { Pagination } from 'ui/components/Pagination';
import { useSearchParams } from 'react-router-dom';

const FILTER_OPTIONS = [
    { value: 'all', label: 'Все пользователи' },
    { value: 'ban', label: 'Забаненные' },
    { value: 'not_ban', label: 'Не забаненные' },
];

const UsersPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalPages, setTotalPages] = useState(1);
    const [filter, setFilter] = useState(FILTER_OPTIONS[0]);
    const [initializing, setInitializing] = useState(true);

    useEffect(() => {
        const offsetParam = searchParams.get('offset');
        const initialFilter = FILTER_OPTIONS.find((option) => option.value === offsetParam) || FILTER_OPTIONS[0]

        setFilter(initialFilter)
        setInitializing(false);
    }, [searchParams])

    const fetchAndSetUsers = useCallback(async () => {
        if (initializing) return;
        
        setLoading(true);
        setError(null);

        try {
            const { value } = filter;

            const isFilterEnabled = value !== 'all';
            const isBanned = value === 'ban';

            const response = await getUsers(currentPage, 10, isFilterEnabled, isBanned);

            setUsers(response.data.users);
            setTotalPages(response.data.totalPages);

            setSearchParams((prevParams) => {
                const params = new URLSearchParams(prevParams);
                params.set('offset', value);
                return params;
            });

        } catch (err) {
            setError(err.message || 'Произошла ошибка при загрузке данных.');
        } finally {
            setLoading(false);
        }
    }, [filter, currentPage, initializing]);

    useEffect(() => {
        fetchAndSetUsers();
    }, [fetchAndSetUsers]);

    const handleFilterChange = (selectedOption) => {
        setFilter(selectedOption);
        setCurrentPage(1);
    };

    if (initializing) {
        return <p>Загрузка...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <PageWrap>
            <PageHeader>
                <Select
                    options={FILTER_OPTIONS}
                    value={filter}
                    onChange={handleFilterChange}
                />
            </PageHeader>
            <UsersList users={users} loading={loading} />
            <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setIndex={setCurrentPage}
            />
        </PageWrap>
    );
};

export default UsersPage;
