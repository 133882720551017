import { Pagination } from "ui/components/Pagination";
import { Card, Form, NotElements } from ".";
import { CoursesListBlock, CoursesListWrap, PaginationWrap } from "../styled";
import { useState } from "react";
import groupsStore from "store/groupsStore";
import { observer } from "mobx-react";

const UsersList = observer(({ group }) => {
    const [currentPage, setCurrentPage] = useState(groupsStore.selectedGroup.userPageInfo.currentPage);
    
    return (
        <CoursesListBlock>
            <Form type={"users"} text={"Добавить пользователя"} title={"Пользователи"} />

            <CoursesListWrap>
                {
                    group?.users.length !== 0
                        ? group?.users.map((userGroup, index) => (
                            <Card
                                key={`${groupsStore.selectedGroup.id}-${userGroup.user?.id}-${index}`}
                                item={userGroup}
                                type="user"
                                group={group}
                            />
                        ))
                        : <NotElements text={"На данный момент в группе нет пользователей"} />
                }
            </CoursesListWrap>
            {
                group.users.length !== 0 && (
                    <PaginationWrap>
                        <Pagination
                            currentPage={currentPage}
                            setIndex={setCurrentPage}
                            totalPages={groupsStore.selectedGroup.userPageInfo.totalPages}
                        />
                    </PaginationWrap>
                )
            }
        </CoursesListBlock>
    );
})

export default UsersList;
