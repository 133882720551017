import styled from "styled-components";
import { CardWrap, Email, RemoveButton } from "../styled";
import groupsStore from "store/groupsStore";

export const CardImage = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
`;

export const CardContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    gap:8px;
`;
const Card = ({ item, type, group }) => {
    const isCourse = type === "course";
    const imgSrc = isCourse ? item.course?.img : item.user?.img || './../img/Avatar.jpg';
    const label = isCourse ? item.label : item.user_name;
    const email = !isCourse ? item.mail : null;
    const surname = !isCourse ? item.user_surname ? item.user_surname : '' : '';
  
    const handleRemove = async () => {
        if (isCourse) {
            await groupsStore.deleteCourse(group.id, item.id);
            await groupsStore.loadCourseNotGroup(group.id);
        } else {
            await groupsStore.deleteUser(group.id, item.id);
            await groupsStore.loadUsersNotGroup(group.id);
        }
    }

    return (
        <CardWrap key={isCourse ? item?.courseId : item.id}>
            <CardImage src={imgSrc} alt={label} />
            <CardContent>
                <p>ID: {item.id}</p>
                <p style={{ fontWeight: 'bold' }}>{label + ' ' + surname}</p>
                {email && <Email>{email}</Email>}
            </CardContent>
            {
                isCourse
                    ? <RemoveButton onClick={(e) => handleRemove(e)}>Удалить</RemoveButton>
                    : <RemoveButton onClick={(e) => handleRemove(e)}>Удалить</RemoveButton>
            }
        </CardWrap>
    );
};

export default Card;
